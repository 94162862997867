

/**
 *
 * @author IboMade
 */
import confirmModal from "@/mixins/confirmModal/confirmModal";
import {Component, Mixins} from "vue-property-decorator";
import {DeviceEndPoint} from "@/services/InventoryService";
import IbomadeUserService from "@/services/ibomadeUserService"
import {Projects} from "@/services/projectService"
import {mapGetters} from "vuex";
import {SimulationService} from "@/services/SimulationService";


@Component({
  name: "Jobs",
  computed: {
    ...mapGetters("Theme", {
      isProductiveSystem: "GET_PRODUCTIVE_SYSTEM"
    })
  }
})
export default class Jobs extends Mixins(confirmModal) {

  private totalDelay: any = 1000;
  private numberOfSimulator: any = "";

  // Load if DOM is rendered

  /**
   * 1. Job
   *
   * All need for changing state of Device is here
   * ----------------------------------------------
   * ----------------------------------------------
   * ----------------------------------------------
   * ----------------------------------------------
   *
   */

  async changeIncorrectDeviceState() {

    var container = this.$refs.logChangeStateContainer;
    this.jobStarted(container, 'jobChangeDeviceStateButton');

    // Call Function to Change Device State
    let response = await DeviceEndPoint.changeAllDeviceState();

    if (response.status === 202) {
      if (response.data.length > 0) {

        var counter = 0;

        // Logs all deleted user
        response.data.forEach((value: any, key: any) => {
          counter++;

          var message = this.$t("job.changeDeviceState.message").toString()
            .replace("(serialnumber)", value.serialnumber)
            .replace("(oldstate)", value.oldstate)
            .replace("(newstate)", value.newstate);

          this.logJobAddEntriesWithDelay(container, message);

          if (counter === response.data.length) {
            this.logJobIsSucceced(container, "jobChangeDeviceStateButton");
          }
        });

      } else {
        this.logNothingChange(container, "jobChangeDeviceStateButton");
      }

    } else {
      this.logJobFailed(container);
      this.enableButton('jobChangeDeviceStateButton');
      this.scrollToBottom(container)
    }
  }


  /**
   * 2. Job
   *
   * Delete all user without project assignment
   * ------------------------------------------
   * ------------------------------------------
   * ------------------------------------------
   * ------------------------------------------
   *
   */

  processProjectUserData(container: any, response: any) {

    this.logJobAddEntriesWithDelay(container, this.$t("job.deleteUserWithoutProjectAssignment.readUserSucceded").toString());
    this.logJobAddEntriesWithDelay(container, this.$t("job.deleteUserWithoutProjectAssignment.processedData").toString());

    var userIds: Array<String> = [];

    // filter only the ids
    response.data.forEach((value: any, key: any) => {
      userIds.push(value.uid);
    });

    this.logJobAddEntriesWithDelay(container, this.$t("job.deleteUserWithoutProjectAssignment.processedDataSucceded").toString());
    return userIds;

  }

  async getAllProjectUser(container: any) {

    this.logJobAddEntriesWithDelay(container, this.$t("job.deleteUserWithoutProjectAssignment.getAllUser").toString());

    // Backend Request
    var response = await Projects.getAllProjectUser();

    if (response.status === 200) {
      return this.processProjectUserData(container, response);

    } else {
      this.logJobFailed(container);
      this.enableButton('jobDeleteProjectsWithoutAssignmentButton');
    }
  }

  async deleteUserWithoutProjectAssignment() {

    var container = this.$refs.logDeleteUserWithoutProjectAssignment;

    // get all Users from every project and filter the ids
    var responseProjectUser = await this.getAllProjectUser(container);

    // if user available
    if (Array.isArray(responseProjectUser)) {

      // Backend Request to delete unassgined user and agency
      var responseUserDelete:any = await IbomadeUserService.deleteUserWithoutProjectAssignment(responseProjectUser);

      if (responseUserDelete.status === 202) {
        if (responseUserDelete.data.length > 0) {

          var counter = 0;

          responseUserDelete.data.forEach((value: any, key: any) => {
            counter++;

            var message = this.$t("job.deleteUserWithoutProjectAssignment.message").toString()
              .replace("(firstname)", value.firstname)
              .replace("(lastname)", value.lastname)
              .replace("(role)", value.role.slice(1, -1))
              .replace("(email)", value.email);

            this.logJobAddEntriesWithDelay(container, message);

            if (counter === responseUserDelete.data.length) {
              this.logJobIsSucceced(container, "jobDeleteProjectsWithoutAssignmentButton");
            }
          });

        } else {
          this.logNothingChange(container, "jobDeleteProjectsWithoutAssignmentButton");
        }

      } else {
        this.logJobFailed(container);
        this.enableButton('jobDeleteProjectsWithoutAssignmentButton');
        this.scrollToBottom(container)
      }
    } else {
      this.logJobAddEntriesWithDelay(container, this.$t("job.deleteUserWithoutProjectAssignment.noUserFound").toString());
      this.enableButton('jobDeleteProjectsWithoutAssignmentButton');
    }
  }


  // DELTE ALL USER WHICH PROJECT IS ARCHIVED
  async deleteUserWithArchivedProject() {

    var container = this.$refs.logDeleteUserWithoutProjectAssignment;
    this.jobStarted(container, 'jobDeleteProjectsWithoutAssignmentButton');
    var responseArchivedProjectUser = await Projects.getAllArchivedProjectUser();

    // Check if the user is "user" or "agency"
    if(responseArchivedProjectUser.status == 200) {

      var counter = 0;
      responseArchivedProjectUser.data.forEach(async (value: any, key: any) => {
        var user_id = value.uid;

        var user = await IbomadeUserService.getUser(user_id);

        if(user.status == 200) {
          counter++;

          var role = user.data.roles[0].name;

          if(role == 'user' || role == 'agency') {
            var responseDeleteUser = await Projects.deleteUser(value.id)

          }

        } else {
          this.error(container, 'jobDeleteProjectsWithoutAssignmentButton');
        }

        if (key === (responseArchivedProjectUser.data.length - 1) ) {
          var responseDeleteWithouProjectAssignment = await this.deleteUserWithoutProjectAssignment();
        }
      });

    } else {
      this.error(container, 'jobDeleteProjectsWithoutAssignmentButton');
    }
  }


  /**
   * 3. Job
   *
   * All need for Delete Items and Projects for Job
   * ----------------------------------------------
   * ----------------------------------------------
   * ----------------------------------------------
   * ----------------------------------------------
   *
   */
  async deleteItemsAndProjects() {

    var container = this.$refs.logDeleteItemsAndProjects;
    this.jobStarted(container, 'jobDeleteProjectsAndItemsButton');

    // First - Delete all Projects
    // ---------------------------
    this.logJobAddEntriesWithDelay(container, this.$t("job.deleteItemsAndProjects.deleteAllProjects").toString());
    var responseProject = await Projects.deleteAll();

    if (responseProject.status == 200) {

      this.logJobAddEntriesWithDelay(container, this.$t("job.deleteItemsAndProjects.deleteAllProjectsSucceded").toString());

      // Second - Delete all Items
      // -------------------------
      this.logJobAddEntriesWithDelay(container, this.$t("job.deleteItemsAndProjects.deleteAllDevices").toString());
      var response = await DeviceEndPoint.deleteAll();

      if (response.status == 200) {
        this.logJobAddEntriesWithDelay(container, this.$t("job.deleteItemsAndProjects.deleteAllDevicesSucceded").toString());
        this.logJobIsSucceced(container, 'jobDeleteProjectsAndItemsButton');

      } else {
        this.logJobAddEntriesWithDelay(container, this.$t("job.deleteItemsAndProjects.deleteAllDevicesError").toString());
        this.enableButton('jobDeleteProjectsAndItemsButton');
      }

    } else {
      this.logJobAddEntriesWithDelay(container, this.$t("job.deleteItemsAndProjects.deleteAllProjectsError").toString());
      this.enableButton('jobDeleteProjectsAndItemsButton');
    }
  }

  confirmDeleteItemsAndProjects() {
    this.$bvModal
      .msgBoxConfirm(this.$t("job.deleteItemsAndProjects.confirmDeleteButton").toString(), {
        title: (this as any).$t("job.deleteItemsAndProjects.confirmHeader"),
        size: "md",
        buttonSize: "md",
        okVariant: "success",
        headerClass: "p-2 border-bottom-0 bg-light",
        footerClass: "p-2 border-top-0",
        centered: true,
        okTitle: (this as any).$t("utils.alertBox.ok"),
        cancelVariant: "light",
        cancelTitle: (this as any).$t("utils.alertBox.cancel")
      })
      .then((value: boolean) => {
        if (value) {
          this.deleteItemsAndProjects();
        }
      });
  }


  /**
   * 4. Job
   *
   * Create Device with Simulator
   * -----------------------------
   * -----------------------------
   * -----------------------------
   * -----------------------------
   *
   */
  async startDeviceSimulator() {

    var container = this.$refs.logCreateDeviceSimulator
    this.jobStarted(container, 'jobStartSimulator');

    this.logJobAddEntriesWithDelay(container, this.$t("job.simulator.startText").toString());

    var response = await SimulationService.simStart(this.numberOfSimulator);

    if (response.status == 200) {

      var message = this.$t("job.simulator.message").toString()
        .replace("(numberOfSimulator)", this.numberOfSimulator)

      this.logJobAddEntriesWithDelay(container, message);
      this.logJobIsSucceced(container, "jobStartSimulator");

      this.$bvModal.hide('startSimulatorModal');
      this.numberOfSimulator = "";

    } else {
      this.logJobFailed(container);
    }

  }

  async stopDeviceSimulator() {

    var container = this.$refs.logCreateDeviceSimulator
    this.jobStarted(container, 'jobStopSimulator');

    var response = await SimulationService.simStop();

    if (response.status == 200) {
      this.logJobAddEntriesWithDelay(container, this.$t("job.simulator.stopText").toString());
      this.logJobIsSucceced(container, "jobStopSimulator");

    } else {
      this.logJobFailed(container);
    }
  }


  /**
   * 5. Job
   *
   * Change All Project States in Inventory-Service
   * ----------------------------------------------
   * ----------------------------------------------
   * ----------------------------------------------
   * ----------------------------------------------
   *
   */

  async getAllProjectData(container: any) {

    this.logJobAddEntriesWithDelay(container, this.$t("job.changeProjectState.getAllProjects").toString());

    var response = await Projects.allProjectsList();

    if (response.status == 200) {

      this.logJobAddEntriesWithDelay(container, this.$t("job.changeProjectState.getAllProjectsSucceded").toString());
      this.logJobAddEntriesWithDelay(container, this.$t("job.changeProjectState.getAllProjectsProcessed").toString());

      return response;

    } else {
      this.logJobAddEntriesWithDelay(container, this.$t("job.changeProjectState.getAllProjectsError").toString());
    }
  }

  async updateProjectState() {

    var container = this.$refs.logUpdateProjectState;
    this.jobStarted(container, 'jobUpdateProjectState');

    var responseProject: any = await this.getAllProjectData(container);

    if (responseProject && responseProject.data.projectList.length > 0) {

      var projectData: any = {};
      var changes: number = 0;
      var counter = 0;

      for (const value of responseProject.data.projectList) {

        projectData = {};
        projectData["id"] = value.id;
        projectData["name"] = value.name;
        projectData["statusId"] = value.status.id;

        var responseInventory = await DeviceEndPoint.updateProjectState(projectData);

        if (responseInventory.status === 202) {

          var projectStates: any = {
            1: "planning",
            2: "active",
            3: "finished",
            4: "archived",
            5: "dismantling"
          }

          if(Object.keys(responseInventory.data).length > 0) {

            changes++;
            this.logJobAddEntriesWithDelay(container, this.$t("job.changeProjectState.updateProjectsStateMessage").toString()
              .replace("(name)", responseInventory.data.name)
              .replace("(statusId)", value.status.id)
              .replace("(statusName)", projectStates[value.status.id]));
          }

          counter++;
          if (counter === responseProject.data.projectList.length) {
            if(changes <= 0) {
              this.logNothingChange(container, "jobUpdateProjectState");
            } else {
              this.logJobIsSucceced(container, "jobUpdateProjectState");
            }
          }

          continue;
        } else {
          this.logJobAddEntriesWithDelay(container, this.$t("job.changeProjectState.updateProjectsStateError").toString());
          break;
        }
      }
    }
  }

  /**
   * GLOBAL!!
   *
   * Functions which can use in every job as helper
   * ----------------------------------------------
   * ----------------------------------------------
   * ----------------------------------------------
   * ----------------------------------------------
   *
   */


  error(container: any, button: any) {
    this.logJobFailed(container);
    this.enableButton(button);
    this.scrollToBottom(container)
  }

  jobStarted(container: any, button: string) {
    this.disableButton(button);
    this.totalDelay = 1000;
    this.logJobStarted(container);
  }

  logJobStarted(container: any) {
    container.innerHTML = this.getFormattedDate() + this.$t("job.startText");
  }

  logJobFailed(container: any) {
    container.innerHTML = this.getFormattedDate() + this.$t("job.error");
  }

  logJobNoChanges(container: any) {
    setTimeout(() => {
      container.innerHTML += "<br>" + this.getFormattedDate() + this.$t("job.noChanges");
    }, this.totalDelay);
  }

  logJobIsSucceced(container: any, button: string) {
    setTimeout(() => {
      container.innerHTML += "<br>" + this.getFormattedDate() + this.$t("job.succeded");
      this.enableButton(button);
      this.scrollToBottom(container)
    }, this.totalDelay + 1000);
  }

  logNothingChange(container: any, button: string) {
    this.logJobNoChanges(container);
    this.logJobIsSucceced(container, button);
  }

  logJobAddEntriesWithDelay(container: any, message: String) {

    this.totalDelay += 1000; // Add 1 Secound Delay

    setTimeout(() => {
      container.innerHTML += `<br> ${this.getFormattedDate()} - ${message}`;
      this.scrollToBottom(container)
    }, this.totalDelay);
  }

  disableButton(buttonId: string) {
    const button = document.getElementById(buttonId);
    if (button) {
      button.setAttribute('disabled', 'disabled');
    }
  }

  enableButton(buttonId: string) {
    const button = document.getElementById(buttonId);
    if (button) {
      button.removeAttribute('disabled');
    }
  }

  getFormattedDate() {
    const now = new Date();

    const formattedDate = now.toLocaleDateString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    })

    return formattedDate;
  }

  openModalSimulator() {
    this.disableButton('submitButtonSimulatorModal');
    this.$bvModal.show('startSimulatorModal');
  }

  submitSimStart() {
    if (this.isFormValid) {
      this.startDeviceSimulator();
    }
  }

  scrollToBottom(container: any) {
    container.scrollTop = container.scrollHeight;
  }

  // Give a value back - same as computed: {}
  get isFormValid(): boolean {
    if (this.numberOfSimulator.trim() > 0) {
      return this.numberOfSimulator.trim() !== ''
    } else {
      return false
    }
  }
}
