/***
 * Mixing  contain the double check  when user commit and action
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [✔] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update to typescript.
 */
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class confirmModal extends Vue {
  /*----------  Methods  ----------*/
  confirm(msg: string, action: Function, resetAction?: Function) {
    this.$bvModal
      .msgBoxConfirm(msg, {
        title: (this as any).$t("project.section.displayInterval.confirmModals.title"),
        size: "md",
        buttonSize: "md",
        okVariant: "success",
        headerClass: "p-2 border-bottom-0 bg-light",
        footerClass: "p-2 border-top-0",
        centered: true,
        okTitle: (this as any).$t("utils.alertBox.ok"),
        cancelVariant: "light",
        cancelTitle: (this as any).$t("utils.alertBox.cancel")
      })
      .then(async (value: boolean) => {
        if (value) {
          await action();
        } else {
          if (resetAction) {
            await resetAction();
          }
        }
      });
  }
}
