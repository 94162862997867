import axios from "axios";
import router from "@/router";

axios.defaults.baseURL = process.env.VUE_APP_LVMS_SIM;

const SimulationService = {
  createAxiosInstance() {
    const axiosProjects = axios.create({
      baseURL: process.env.VUE_APP_LVMS_SIM, // muss ersetzt werden
      headers: {
        // @ts-ignore
        Authorization: "Bearer " + router.app.$keycloak.token,
        Accept: "application/json"
        // 'Content-Type': 'application/json'
      }
    });
    return axiosProjects;
  },
  async simStart(numberOfSimulation: number) {
    const res = await this.createAxiosInstance().get(`/start/${numberOfSimulation}`);
    return res;
  },
  async simStop() {
    const res = await this.createAxiosInstance().get(`/stop`);
    return res;
  }
};

export { SimulationService };
